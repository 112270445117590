import React, { useContext, useState } from 'react';
// import CompleteIcon from './PostApplicationComponents/CompleteIcon';
import {
  experiments,
  LEAD_GEN_PROGRAM_ID_FELLOWSHIP_PLUS,
  LEAD_GEN_PROGRAM_ID_FULL_STACK,
  LEAD_GEN_PROGRAM_ID_QA,
} from '@src/constants/LeadGenerationConstants/index';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import CrioButton from '@components/v5/CrioButton/index';
import { GTM } from '@src/analytics/gtm';
import { gtmEvents } from '@src/analytics/gtmEventsConstant';
import { ELeadSquaredActivityCode } from '@src/constants/leadsquaredActivityConstants/index';
import { GlobalStateContext } from '@src/context/GlobalContextProvider';
import { useAuth } from '@src/firebase/useAuth';
import { doLoginToBackend } from '@src/api/index';
import toast from 'react-hot-toast';
import Navigation from '@components/Navigation';
import FooterV2 from '@components/FooterV2/index';

// import CompleteIcon from './PostApplicationComponents/CompleteIcon';
import IconDetailText from './PostApplicationComponents/IconDetailText';
import {
  faMapLocationDot,
  faGraduationCap,
  faUserTie,
  faLightbulbOn,
} from '@fortawesome/pro-regular-svg-icons';
import FloatingHeroForm from './PostApplicationComponents/FloatingHeroForm';
import RoundedLayout from '@components/v5/layouts/RoundedLayout/index';
import FadeInWhenVisible from './PostApplicationComponents/FadeInWhenVisible';
import VideoTestimonialCarousel from '@components/v5/VideoTestimonialCarousel/index';
import TestimonialSlider from '@components/TestimonialSlider/index';
import { Testimonials } from '@src/PageData/data';
import StatsPanel from '@components/StatsPanel/index';
import SurveyForm from './PostApplicationComponents/SurveyFormButton';
import CompleteIcon from './PostApplicationComponents/CompleteIcon';
import useCrioStat from '@src/hooks/useCrioStats';
import RegistrationSuccess from './RegistrationSuccess/index';
import OTPVerification from './OTPVerification/index';
import EditPhoneNumber from './EditPhoneNumber/index';
import Cookies from 'js-cookie';

export default function PostAppNew(props) {
  const {
    programID,
    leadFlow,
    CompanyImagesData,
    TestimonialImagesData,
    Icons,
  } = props;

  const { phone } = useContext(GlobalStateContext);
  const [currentPage, setCurrentPage] = useState(() => {
    return Cookies.get('isPhoneVerified') ? 'success' : 'otp';
  });
  const [isPhoneVerified, setIsPhoneVerified] = useState(false);

  const isSdAndTC_FLOW =
    programID === LEAD_GEN_PROGRAM_ID_FULL_STACK &&
    leadFlow === experiments.TC_FLOW;

  const isQA = programID === LEAD_GEN_PROGRAM_ID_QA;
  const isSde2 = programID === LEAD_GEN_PROGRAM_ID_FELLOWSHIP_PLUS;

  let heroVideoSRC = isSdAndTC_FLOW
    ? 'https://www.youtube.com/embed/gB8fVYJJbvc'
    : programID === LEAD_GEN_PROGRAM_ID_FULL_STACK
    ? 'https://www.youtube.com/embed/hSWoJiYZ4-M'
    : 'https://www.youtube.com/embed/x1LcDKKFRJU';

  const benefits = [
    {
      image: 'BulbIcon',
      content: `Get exclusive insights about Crio’s Programs and Placements`,
    },
    {
      image: 'FreeLabelIcon',
      content: `Experience #learnbydoing on Crio’s Platform for free!`,
    },
    {
      image: 'BookIcon',
      content: `Unlock a Personalized Career Plan curated by our experienced Program Advisors`,
    },
    {
      image: 'SuitcaseIcon',
      content: `Learn how Crio can help you bag top-tech roles
    `,
    },
  ];

  if (isSde2) {
    return (
      <SDE2PostAppPage
        isQA={isQA}
        isSde2={isSde2}
        isSdAndTC_FLOW={isSdAndTC_FLOW}
        programID={programID}
        heroVideoSRC={heroVideoSRC}
        TestimonialImagesData={TestimonialImagesData}
        CompanyImagesData={CompanyImagesData}
        dark={props.dark}
      />
    );
  }

  return (
    <div className="h-screen w-screen overflow-hidden">
      <nav className="p-2">
        <Link to="/">
          <div>
            <StaticImage
              loading="eager"
              src={`../../images/Crio_Dark.png`}
              placeholder="none"
              alt="Crio.Do"
              className="w-[100px] md:w-[120px]"
              imgClassName={{ objectFit: 'contain' }}
            />
          </div>
        </Link>
      </nav>
      <div className="relative h-screen w-screen">
        <div className="absolute top-0 left-0 z-[100] flex h-[calc(100vh-50px)] w-screen items-center justify-center">
          <div className="relative mx-4 flex flex-col rounded-2xl bg-crio-green-300 shadow-lg md:h-[599px] md:w-[1083px] md:flex-row md:rounded-l-2xl">
            <div className="rounded-2xl md:w-[25%] md:rounded-l-2xl">
              <StaticImage
                loading="eager"
                src={`../../images/post-application-page/CardImageDesktop.png`}
                placeholder="none"
                className="hidden h-full md:block"
                imgClassName={{ objectFit: 'contain' }}
              />
              <StaticImage
                loading="eager"
                src={`../../images/post-application-page/CardImageMobile.png`}
                placeholder="none"
                className="block h-full md:hidden"
                imgClassName={{ objectFit: 'cover' }}
              />
            </div>
            <div className="flex h-full flex-col rounded-b-2xl bg-[#F2FBF7] py-7 px-4 font-manrope md:w-[75%] md:rounded-r-2xl md:rounded-l-[0px] md:px-8 md:py-16">
              {!isPhoneVerified && currentPage === 'otp' ? (
                <OTPVerification
                  onChangePage={setCurrentPage}
                  setIsPhoneVerified={setIsPhoneVerified}
                />
              ) : currentPage === 'editPhone' ? (
                <EditPhoneNumber
                  onChangePage={setCurrentPage}
                  currPhone={phone}
                />
              ) : (
                <RegistrationSuccess Icons={Icons} />
              )}
            </div>
          </div>
        </div>
        <StaticImage
          loading="eager"
          src="../../images/PostAppBackground.svg"
          placeholder="none"
          alt="Crio.Do"
          className="absolute left-0 top-0 h-screen w-screen"
          // imgClassName={{ objectFit: 'cover' }}
        />
      </div>
    </div>
  );
}

const SDE2PostAppPage = ({
  isQA,
  isSde2,
  isSdAndTC_FLOW,
  programID,
  heroVideoSRC,
  TestimonialImagesData,
  CompanyImagesData,
  dark,
}) => {
  const { communityNumbers } = useCrioStat();
  const communityHeading = communityNumbers[0].label;

  return (
    <>
      <div className="fixed-header">{<Navigation hideCTA></Navigation>}</div>
      <div className="pt-[105px]">
        <section
          className={`relative flex w-screen flex-col items-center justify-center gap-4 gap-y-8 bg-v5-green-500 px-4 pt-10 ${
            !isQA ? 'pb-[80px]' : 'pb-[180px]'
          } text-v5-neutral-200`}
        >
          <div className="flex flex-col items-center justify-center gap-y-4 text-center">
            <CompleteIcon />

            {!isSdAndTC_FLOW && (
              <div className="text-3xl font-bold">Congratulations !</div>
            )}

            {isSdAndTC_FLOW ? (
              <div className="text-2xl font-bold">
                Thanks for booking Crio’s Free Trial Session to kickstart your
                journey!
              </div>
            ) : (
              <div className="text-xl">
                Your slot for{' '}
                <span className="font-bold">Crio’s Free Trial Workshop</span> is
                confirmed
              </div>
            )}
          </div>

          <FloatingHeroForm programID={programID} />

          <div className="mx-3 flex w-full flex-col items-center justify-evenly gap-y-16 text-xl lg:flex-row">
            <div>
              <div className="mb-4 text-center">
                Attend{' '}
                <span className="font-bold">
                  Crio’s Free Trial{' '}
                  {`${isSdAndTC_FLOW ? 'Session' : 'Workshop'}`}
                </span>{' '}
                and walk away with,
              </div>
              <div className="flex flex-col gap-y-2">
                <IconDetailText
                  icon={faMapLocationDot}
                  text={`A detailed ${
                    isSde2
                      ? 'senior software development'
                      : programID === LEAD_GEN_PROGRAM_ID_FULL_STACK
                      ? 'software development'
                      : 'QA Automation'
                  } career roadmap`}
                />
                <IconDetailText
                  icon={faGraduationCap}
                  text="Exclusive & assured scholarships"
                />
                <IconDetailText
                  icon={faUserTie}
                  text={
                    isSdAndTC_FLOW
                      ? "Insights from Crio's Certified Tech & Career Experts"
                      : 'Insights from seasoned industry professionals'
                  }
                />
                <IconDetailText
                  icon={faLightbulbOn}
                  text={
                    isSdAndTC_FLOW
                      ? 'Knowing how #LearnByDoing happens at Crio'
                      : 'Hands-on learning experience on Crio platform'
                  }
                />
              </div>
            </div>

            <div className="flex h-[207px] w-[350px] items-center justify-center sm:h-[263px] sm:w-[450px]">
              {isSde2 ? (
                <StaticImage
                  loading="lazy"
                  src="../../images/sde2/sde2RegisteredHero.png"
                  placeholder="blurred"
                  alt="BVS"
                  className="h-[250px] w-[260px]"
                />
              ) : (
                <iframe
                  width="100%"
                  height="100%"
                  src={heroVideoSRC}
                  title="Post Application Page Video"
                  frameborder="0"
                  loading="lazy"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen="allowfullscreen"
                />
              )}
            </div>
          </div>

          {isSdAndTC_FLOW && (
            <SurveyForm
              programID={programID}
              surveyButtonText={
                'Get minimum Assured CTC you can bag with Crio!'
              }
            />
          )}
        </section>

        <RoundedLayout roundedBg="bg-v5-green-100" className="bg-white">
          <div
            className={`container ${
              !isQA ? 'mt-8' : 'mt-[320px] sm:mt-[180px]'
            } py-20`}
          >
            <div className="flex flex-col items-center gap-y-5 text-center">
              <h2
                className={`font-manrope font-extrabold capitalize leading-snug  ${
                  dark ? 'text-v5-neutral-200' : ''
                }`}
              >
                {communityHeading}
              </h2>
              <div className="text-xl">
                <span className="font-bold">Meet and grow</span> with a
                community of passionate developers who have taken their careers
                to new heights with Crio.
              </div>
            </div>

            <div className="mt-16">
              <StatsPanel />
            </div>

            <div className="mt-24 flex flex-col gap-y-12">
              <div className="text-center text-2xl font-bold">
                Developers agree that the best way to learn is by doing
              </div>
              <TestimonialSlider
                testimonials={Testimonials}
                companyImagesData={CompanyImagesData}
                testimonialImagesData={TestimonialImagesData}
              />
            </div>

            <div>
              <FadeInWhenVisible
                variants={{
                  visible: { opacity: 1 },
                  hidden: { opacity: 0 },
                }}
              >
                <VideoTestimonialCarousel
                  dark={dark}
                ></VideoTestimonialCarousel>
              </FadeInWhenVisible>
            </div>
          </div>
        </RoundedLayout>
      </div>
      <FooterV2></FooterV2>
    </>
  );
};

const SignIn = () => {
  const auth = useAuth();
  const state = useContext(GlobalStateContext);

  const handlePostFirebaseLogin = async (idToken, cb) => {
    let response = await doLoginToBackend(idToken);

    if (response) {
      cb();
    } else {
      toast.error(
        'Authentication failed due to poor network connection. Please refresh your browser and try again.',
        { duration: 8000, classname: { fontSize: '14px' }, id: 'auth' },
      );
    }
  };

  async function handleLogin(e) {
    GTM.track(gtmEvents.USER_SIGNIN);
    GTM.leadSquaredEvent(ELeadSquaredActivityCode.SIGN_IN_CLICKED, state.email);
    e.preventDefault();
    if (auth?.user) {
      const idToken = await auth.getIdToken();

      // track signup in web-engage
      GTM.track(gtmEvents.USER_SIGNEDUP, {
        email: auth?.user?.email,
        displayName: auth?.user?.displayName,
      });

      handlePostFirebaseLogin(idToken, () => {
        window.open('/learn/home/', '_self');
      });
      return;
    }
    auth.signin().then(async (res) => {
      const idToken = res.idToken;

      // track signup in web-engage
      GTM.track(gtmEvents.USER_SIGNEDUP, {
        email: res?.user?.email,
        displayName: res?.user?.displayName,
      });

      handlePostFirebaseLogin(idToken, () => {
        window.open('/learn/home/', '_self');
      });

      return;
    });
  }

  return (
    <div>
      <CrioButton
        variant="primary"
        className={`flex h-[40px] justify-between px-8 text-xl md:h-[60px] md:w-[300px]`}
        onClick={handleLogin}
      >
        <div className="text-base md:ml-10 md:text-2xl">{'Sign-in Now'}</div>
      </CrioButton>
    </div>
  );
};

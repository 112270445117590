import CrioButton from '@components/v5/CrioButton/index';
import { GTM } from '@src/analytics/gtm';
import { gtmEvents } from '@src/analytics/gtmEventsConstant';
import { doLoginToBackend } from '@src/api/index';
import { ELeadSquaredActivityCode } from '@src/constants/leadsquaredActivityConstants/index';
import { GlobalStateContext } from '@src/context/GlobalContextProvider';
import { useAuth } from '@src/firebase/useAuth';
import { useContext } from 'react';
import toast from 'react-hot-toast';
import React from 'react';

export const SignIn = () => {
  const auth = useAuth();
  const state = useContext(GlobalStateContext);

  const handlePostFirebaseLogin = async (idToken, cb) => {
    let response = await doLoginToBackend(idToken);

    if (response) {
      cb();
    } else {
      toast.error(
        'Authentication failed due to poor network connection. Please refresh your browser and try again.',
        { duration: 8000, classname: { fontSize: '14px' }, id: 'auth' },
      );
    }
  };

  async function handleLogin(e) {
    GTM.track(gtmEvents.USER_SIGNIN);
    GTM.leadSquaredEvent(ELeadSquaredActivityCode.SIGN_IN_CLICKED, state.email);
    e.preventDefault();
    if (auth?.user) {
      const idToken = await auth.getIdToken();

      // track signup in web-engage
      GTM.track(gtmEvents.USER_SIGNEDUP, {
        email: auth?.user?.email,
        displayName: auth?.user?.displayName,
      });

      handlePostFirebaseLogin(idToken, () => {
        window.open('/learn/home/', '_self');
      });
      return;
    }
    auth.signin().then(async (res) => {
      const idToken = res.idToken;

      // track signup in web-engage
      GTM.track(gtmEvents.USER_SIGNEDUP, {
        email: res?.user?.email,
        displayName: res?.user?.displayName,
      });

      handlePostFirebaseLogin(idToken, () => {
        window.open('/learn/home/', '_self');
      });

      return;
    });
  }

  return (
    <div>
      <CrioButton
        variant="primary"
        className={`flex h-[40px] justify-between px-8 text-xl md:h-[60px] md:w-[300px]`}
        onClick={handleLogin}
      >
        <div className="text-base md:ml-10 md:text-2xl">{'Sign-in Now'}</div>
      </CrioButton>
    </div>
  );
};

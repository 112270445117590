import React from 'react';
import ReactHtmlParser from 'react-html-parser';

export default function StatsPanel() {
  const statsData = [
    {
      stat: '5k+',
      caption:
        'curious developers chose to<br/><strong>#learnbydoing</strong> with Crio',
    },
    { stat: '500+', caption: 'top companies trust Crio talent' },
    {
      stat: '20k+',
      caption: "aspiring developers in the Crio's Community",
    },
  ];

  return (
    <div className="rounded-2xl bg-v5-neutral-200">
      <div className="flex flex-col items-center justify-evenly lg:flex-row">
        {statsData.map((val, idx) => (
          <div
            key={`stat-${idx}`}
            className={`relative flex flex-1 justify-center ${
              idx !== 0
                ? "after:absolute after:left-0 after:top-0 after:h-[0%] after:w-full after:border-b-2 after:border-v5-neutral-300  after:content-[''] lg:after:top-10 lg:after:h-[70%] lg:after:w-[0%] lg:after:border-l-2"
                : ''
            }`}
          >
            <div className="my-16">
              <h1 className={`mb-4 text-center text-7xl text-v5-green-500`}>
                {val.stat}
              </h1>
              <p className="text-center text-lg leading-6 text-v5-neutral-400 lg:w-60">
                {ReactHtmlParser(val.caption)}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

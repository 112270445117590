import { GatsbySeo } from 'gatsby-plugin-next-seo';
import React, { useEffect } from 'react';
import { StaticQuery, graphql } from 'gatsby';
// import Navigation from '@components/Navigation/index';
// import FooterV2 from '@components/FooterV2/index';
import { useQuery } from '@components/extra/useQuery';
import {
  experiments,
  LEAD_GEN_PROGRAM_ID_FELLOWSHIP_PLUS,
  LEAD_GEN_PROGRAM_ID_FULL_STACK,
  LEAD_GEN_PROGRAM_ID_QA,
} from '@src/constants/LeadGenerationConstants/index';
import PostAppNew from '@components/PostApplication/PostAppNew';
import LaunchPostApplicationPage from '@components/v5/launch/LaunchPostApplicationPage/index';
import CrioRebootPostApplicationPage from '@components/CrioRebootPostApplicationPage/index';
import HallOfFamePostApplicationPage from '@components/HallOfFamePostApplicationPage/index';
import { GTM } from '@src/analytics/gtm';
import { gtmEvents } from '@src/analytics/gtmEventsConstant';

export const pageQuery = graphql`
  query {
    ...strapiDataFragment

    allStrapiPrograms {
      ...allStrapiProgramsFragment
    }

    CompanyImages: allFile(
      filter: { relativePath: { regex: "images/placements/Companies/" } }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            blurredOptions: {}
            width: 400
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
      }
    }

    TestimonialImages: allFile(
      filter: { relativePath: { regex: "images/placements/Testimonials/" } }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            blurredOptions: {}
            width: 300
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
      }
    }

    Icons: allFile(
      filter: { relativePath: { regex: "images/post-application-page/Icons/" } }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            blurredOptions: {}
            width: 300
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
      }
    }
  }
`;

export default function Registered() {
  const programId = useQuery('programID');
  const getProgramId = () => {
    if (programId === LEAD_GEN_PROGRAM_ID_QA) {
      return LEAD_GEN_PROGRAM_ID_QA;
    } else if (programId === LEAD_GEN_PROGRAM_ID_FELLOWSHIP_PLUS) {
      return LEAD_GEN_PROGRAM_ID_FELLOWSHIP_PLUS;
    } else {
      return LEAD_GEN_PROGRAM_ID_FULL_STACK;
    }
  };

  const leadFlow =
    useQuery('leadFlow') === experiments.TC_FLOW
      ? experiments.TC_FLOW
      : experiments.DEMO_FLOW;

  useEffect(() => {
    GTM.track(gtmEvents.PAGE_VIEW_REGISTERED_V2, {
      programId: getProgramId(),
      slug: 'registered/v2',
    });
  }, []);
  const page = useQuery('page');

  if (page === 'CRIO_REBOOT') {
    return (
      <CrioRebootPostApplicationPage
        page={page}
      ></CrioRebootPostApplicationPage>
    );
  }

  if (page === 'CRIO_QA_FLOW') {
    return <LaunchPostApplicationPage></LaunchPostApplicationPage>;
  }

  if (page === 'HALL_OF_FAME') {
    return <HallOfFamePostApplicationPage></HallOfFamePostApplicationPage>;
  }

  return (
    <StaticQuery
      query={pageQuery}
      render={(data) => {
        const CompanyImagesData = data.CompanyImages.nodes;
        const TestimonialImagesData = data.TestimonialImages.nodes;
        const Icons = data.Icons.nodes;

        console.log('debug >>> ', Icons);

        return (
          <>
            <GatsbySeo
              noindex={true}
              title="Registration Successful"
              description="Build internship-grade projects in a real developer environment and accelerate your full stack or backend developer career with a job-ready project portfolio. Enroll and start for free."
            />

            {/* <div className="fixed-header">
              {<Navigation hideCTA></Navigation>}
            </div> */}

            <PostAppNew
              programID={getProgramId()}
              leadFlow={leadFlow}
              CompanyImagesData={CompanyImagesData}
              TestimonialImagesData={TestimonialImagesData}
              Icons={Icons}
            />

            {/* <FooterV2></FooterV2> */}
          </>
        );
      }}
    ></StaticQuery>
  );
}

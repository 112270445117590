import React from 'react';
//import navigate from gatsby
import Text from '@components/CrioReboot/Typography/Text/index';
import GlassCircleBlur from '@components/GlassMorphism/GlassCircleBlur/index';
import NavbarPostApp from '@components/HallOfFame/Navbar/NavbarPostApp';
import awardCeremonyImg from '../../images/hall-of-fame/award-ceremony.webp';
import playBtnIcon from '../../images/hall-of-fame/play-btn-icon.webp';

export default function HallOfFamePostApplicationPage(props) {
  const shadowStyle = {
    WebkitBoxShadow: '10px 10px 0px -1px rgba(0,0,0,0.75)',
    MozBoxShadow: '10px 10px 0px -1px rgba(0,0,0,0.75)',
    boxShadow: '10px 10px 0px -1px rgba(0,0,0,0.75)',
  };
  return (
    <div className="relative">
      <NavbarPostApp />
      <div className="relative h-[30vh] min-h-screen flex-col  items-center justify-center overflow-hidden bg-v5-green-700 py-10 text-center text-white md:flex md:h-[50vh] md:px-10">
        <div className="relative z-[999] flex justify-center">
          <div className=" mx-8 lg:max-w-[800px]">
            <div className="reveal mb-8 mt-10 md:mt-0">
              <Text variant="h1">
                Thank You For <br className="hidden sm:block"></br>Your
                Application!
              </Text>
            </div>

            <div className="reveal flex flex-col items-center gap-4 md:mb-10">
              <Text className="mb-10">
                We will communicate the next steps to you shortly.
                <br className="hidden sm:block"></br> Meanwhile watch the Crio
                Hall of Fame 2023 award ceremony on our YouTube channel.
              </Text>
            </div>

            <div className="mb-10 flex justify-center">
              <div className="">
                <div className="relative">
                  <div className="relative">
                    <img
                      src={awardCeremonyImg}
                      className="2xl  w-[90vw] rounded-2xl object-cover brightness-50 md:h-[298px] md:w-[500px]"
                      alt=""
                      style={shadowStyle}
                    />
                  </div>

                  <a
                    className="absolute top-0 left-0 flex h-full w-full items-center justify-center"
                    href="https://www.youtube.com/playlist?list=PLrKcFCERg7yYMlz_XThh44uHxwdJfxHmx"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={playBtnIcon}
                      className="h-[48px] w-[48px] md:h-[71px] md:w-[71px]"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pointer-events-none fixed top-0 h-screen w-[calc(100vw-1rem)]">
          <GlassCircleBlur extendedClassName="absolute bg-gradient-to-t from-v5-green-100 to-v5-green-700 right-8 top-[10%]" />
          <GlassCircleBlur extendedClassName="absolute bg-black top-0 left-[2%]" />

          <GlassCircleBlur extendedClassName="absolute bg-gradient-to-t from-v5-green-100 to-v5-green-700 left-0 top-[55%]" />
          <GlassCircleBlur extendedClassName="absolute bg-black top-[50%] right-8" />
        </div>
      </div>
    </div>
  );
}

import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

export default function CompleteIcon({ light }) {
  return (
    <div className="relative select-none">
      <StaticImage
        width={70}
        src="../../../images/PostApplication/SuccessIcons/GreenCircle.png"
        placeholder="blurred"
        imgStyle={{ objectFit: 'contain' }}
      />
      <StaticImage
        width={50}
        src="../../../images/PostApplication/SuccessIcons/GreenTick.png"
        placeholder="blurred"
        imgStyle={{ objectFit: 'contain' }}
        className="absolute top-[8px] right-[2px] z-50"
      />
      {!light ? (
        <StaticImage
          width={55}
          src="../../../images/PostApplication/SuccessIcons/DarkGreenTick.png"
          placeholder="blurred"
          imgStyle={{ objectFit: 'contain' }}
          className="absolute top-[12px] right-[1px]"
        />
      ) : (
        <StaticImage
          width={55}
          src="../../../images/PostApplication/SuccessIcons/LightGreenTick.png"
          placeholder="blurred"
          imgStyle={{ objectFit: 'contain' }}
          className="absolute top-[12px] right-[1px]"
        />
      )}
    </div>
  );
}

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function IconDetailText({ text, icon }) {
  return (
    <div className="flex items-start gap-x-3">
      {
        <FontAwesomeIcon
          icon={icon}
          className="min-w-[30px] text-2xl text-v5-green-100"
        />
      }
      <div className="break-words">{text}</div>
    </div>
  );
}

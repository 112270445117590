import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import navigate from gatsby
import { Link } from 'gatsby';
import CrioButton from '@components/v5/CrioButton/index';
import { twMerge } from 'tailwind-merge';
import Text from '@components/CrioReboot/Typography/Text/index';

export default function CrioRebootPostApplicationPage(props) {
  return (
    <div className="relative h-full  min-h-screen overflow-hidden bg-white text-center md:p-10">
      <div className="mb-8 flex  items-center justify-center sm:mb-5 lg:justify-between ">
        <Link to={'/crio-reboot'}>
          <StaticImage
            loading="eager"
            src="../../images/CrioReboot/RebootLogo.webp"
            placeholder="none"
            width={300}
            alt="Crio logo"
            className="hidden lg:block"
          />
        </Link>

        <div className="z-10 p-3 transition-all sm:relative">
          <a
            href="/?utm_source=automation-edge&utm_medium=post_application&utm_campaign=automation-edge"
            target="_self"
          >
            <FontAwesomeIcon
              className=" cursor-pointer text-launch-neutral-100  hover:opacity-75"
              size="2x"
              icon={['fas', 'times']}
            ></FontAwesomeIcon>
          </a>
        </div>
      </div>
      <div className="flex justify-center">
        <div className=" mx-8 lg:max-w-[800px]">
          <div className="reveal mb-8">
            <Text variant="h1">
              Thank You For <br className="hidden sm:block"></br>Your
              Application!
            </Text>
          </div>

          <div className="reveal  mb-10 flex flex-col items-center gap-4">
            <Text className="mb-10">
              We will communicate the next steps to you shortly.
              <br className="hidden sm:block"></br> Meanwhile join our WhatsApp
              group to get updates about the events.
            </Text>
            <CrioButton
              variant="crio-reboot"
              onClick={() => {
                window.open(
                  'https://rebrand.ly/reboot-2024-whatsapp',
                  '_blank',
                );
              }}
              className={twMerge(`h-[64px] px-6`)}
            >
              <div className="pr-2 text-left">
                <h6 className="font-extrabold">Join Whatsapp Group</h6>
              </div>
            </CrioButton>
          </div>

          <div className="mb-10 flex justify-center">
            <div className="max-w-[300px] md:max-w-[500px]">
              <StaticImage
                loading="eager"
                src="../../images/CrioReboot/Hero.webp"
                placeholder="none"
                alt="Hero Image"
                width={1000}
                imgStyle={{ objectFit: 'contain' }}
                className="w-full"
              />
            </div>
          </div>
          <div className="lg:hidden ">
            <Link to={'/crio-reboot'}>
              <StaticImage
                loading="lazy"
                src="../../images/CrioReboot/RebootLogo.webp"
                placeholder="none"
                width={120}
                alt="Crio logo"
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

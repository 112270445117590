import React from 'react';
import { SignIn } from './SignIn/index';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const benefits = [
  {
    image: 'BulbIcon',
    content: `Get exclusive insights about Crio’s Programs and Placements`,
  },
  {
    image: 'FreeLabelIcon',
    content: `Experience #learnbydoing on Crio’s Platform for free!`,
  },
  {
    image: 'BookIcon',
    content: `Unlock a Personalized Career Plan curated by our experienced Program Advisors`,
  },
  {
    image: 'SuitcaseIcon',
    content: `Learn how Crio can help you bag top-tech roles
    `,
  },
];

export default function RegistrationSuccess({ Icons }) {
  return (
    <>
      <div className="mb-4 md:mb-6">
        <div className="text-2xl font-extrabold md:text-4xl">
          Congratulations!
        </div>
        <div className="mt-4 text-xs font-bold md:text-xl">
          You’ve successfully registered for Crio’s Free Trial.
        </div>
        <div className="my-5 md:mt-10">
          <SignIn />
        </div>
      </div>
      <div>
        <div className="text-xs font-bold text-black md:text-lg">
          Sign-in to Crio’s Platform to start your free trial and
          <br />
        </div>

        <div className="mt-5 flex flex-col gap-2 md:my-6 md:gap-6">
          {benefits.map((benefit) => {
            const image = getImage(
              Icons.find((el) => el.name === benefit.image)?.childImageSharp,
            );

            return (
              <div className="flex gap-4">
                <div className="w-[18px] md:w-[24px]">
                  <GatsbyImage loading="lazy" image={image} alt={'session'} />
                </div>
                <p className="flex-1 text-xs md:text-base">{benefit.content}</p>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

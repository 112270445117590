import CrioButton from '@components/v5/CrioButton/index';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '@src/context/GlobalContextProvider';
import Cookies from 'js-cookie';
import React, { useContext, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import LeadGenerationService from '../../../../utils/lead-generation';

export default function EditPhoneNumber({ onChangePage, currPhone }) {
  const [phone, setPhone] = useState(currPhone);
  const dispatch = useContext(GlobalDispatchContext);
  const globalState = useContext(GlobalStateContext);

  const handleReceiveOTP = async () => {
    let updateLeadPayload = {
      leadID: globalState.globalLeadDocumentId,
      phone: phone?.startsWith('+') ? phone : '+' + phone,
    };

    Cookies.remove('retryCount');

    dispatch({
      type: 'SET_PHONE',
      payload: phone?.startsWith('+') ? phone : '+' + phone,
    });
    if (globalState.globalLeadDocumentId) {
      await LeadGenerationService.updateLead(updateLeadPayload);
    }
    localStorage.setItem('oldPhone', currPhone);
    onChangePage('otp');
  };

  return (
    <div className="flex flex-col items-center text-center font-manrope md:items-start md:text-left">
      <div className="mb-2 text-xl font-extrabold leading-4 md:text-4xl md:leading-[50px]">
        Welcome to Crio.Do
      </div>
      <div className="mb-2 text-[10px] font-normal leading-[18px] text-v5-neutral-500 md:text-[22px] md:leading-9">
        Enter your Registered Mobile Number
      </div>
      <div className="mb-7 text-[10px] font-normal leading-[18px] text-v5-neutral-500 md:text-lg md:leading-9">
        <span>You will receive an OTP here shortly</span>
      </div>

      <div className="mb-8">
        <PhoneInput
          country={'in'}
          value={phone}
          dropdownClass="bg-red-200"
          containerStyle={{
            height: '20px !important',
          }}
          onChange={(phone) => {
            setPhone(phone);
          }}
        />
      </div>

      <div className="mb-7">
        <CrioButton
          disabled={!phone}
          variant="primary"
          className={`flex h-[40px] justify-between px-8 text-xl md:h-[60px] md:w-[300px]`}
          onClick={() => {
            handleReceiveOTP();
          }}
        >
          <div className="text-base md:ml-9 md:text-2xl">{'Receive OTP'}</div>
        </CrioButton>
      </div>

      <div
        className="cursor-pointer text-[10px] font-extrabold text-v5-green-200 underline md:text-lg"
        onClick={() => {
          onChangePage('otp');
        }}
      >
        Go back
      </div>
    </div>
  );
}

import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Button from '@components/Button/index';

import { twMerge } from 'tailwind-merge';
import SurveyForm from './SurveyFormButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GTM } from '@src/analytics/gtm';
import { gtmEvents } from '@src/analytics/gtmEventsConstant';

export default function FloatingHeroForm({ programID }) {
  const isSde2 = programID === 'FELLOWSHIP_PLUS';

  if (programID === 'QA_AUTOMATION_TESTING') {
    return (
      <div className="absolute bottom-[-280px] m-12 flex min-w-[90%] flex-col items-center gap-y-8 rounded-xl bg-v5-neutral-200 p-12 sm:bottom-[-240px] sm:min-w-[80%] md:bottom-[-220px]">
        <div className="text-center text-2xl font-bold text-black">
          Fill a short form to help our team understand your profile better. Our
          team will use this to
          <br />
          share a personalized career plan and starting salary ranges you can
          bag with Crio.
        </div>
        <SurveyForm programID={programID} />
      </div>
    );
  }

  const onButtonClick = () => {
    if (isSde2) {
      window.open('https://form.typeform.com/to/OVGKGddt', '_blank');
    } else {
      GTM.track(gtmEvents.CTA_CLICKED, {
        type: 'USER_PROFILE',
        location: 'POST_APPLICATION_PAGE',
      });
      window.open(
        window.location.origin + '/learn/personalized-plan',
        '_blank',
      );
    }
  };

  return (
    <div className="bottom-[-400px] mx-4 flex min-w-[80%] max-w-[800px] flex-col items-center gap-4 rounded-xl bg-v5-neutral-200 p-4 sm:bottom-[-240px] sm:min-w-[80%] md:bottom-[-220px] md:mx-0 md:flex-row md:gap-y-8 md:p-12 md:py-6">
      <div>
        <div className="h-full w-full">
          <StaticImage
            loading="eager"
            src={'../../../images/user-profiling.webp'}
            placeholder="blurred"
            alt="vmware"
            width={250}
            imgStyle={{ objectFit: 'contain' }}
            className=" max-h-[100px] max-w-[100px]   sm:max-w-none md:max-h-[300px] md:w-[260px]"
          />
        </div>
      </div>
      <div>
        <h3 className="text-center text-xl text-black md:text-left">
          {isSde2
            ? 'Check Program Fitment'
            : 'Land your dream job with a proven personalized plan'}
        </h3>
        <div className="mt-4 text-center  text-lg text-[#757575] md:text-left">
          {isSde2
            ? 'Please complete the profile fitment form so that we can recommend you the best-suited learning path based on your technical skills.'
            : 'Fill in information about your background and skill set. We’ll evaluate where you stand and curate a personalized learning path to help you accelerate your career with Crio.'}
        </div>
        <div className="mt-4 flex  w-full justify-center md:justify-start">
          <Button
            onClick={onButtonClick}
            className={twMerge(
              `natural sm mt-4 flex h-[56px] items-center whitespace-nowrap rounded-[10px] border-2 border-v5-yellow-200 bg-v5-yellow-200 py-4 px-8 font-manrope text-base font-bold capitalize text-black shadow-lg shadow-v5-yellow-200/50 hover:shadow-xl  hover:shadow-v5-yellow-100/50`,
            )}
            type="contained"
          >
            {isSde2 ? 'Get started' : 'Get a personalized plan'}
            <FontAwesomeIcon
              icon={['fas', 'angle-right']}
              className="ml-2 mt-1 text-lg"
              style={{ marginBottom: '2px' }}
            ></FontAwesomeIcon>
          </Button>
        </div>
      </div>
    </div>
  );
}

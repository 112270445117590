import CrioButton from '@components/v5/CrioButton/index';
import { GlobalStateContext } from '@src/context/GlobalContextProvider';
import { GTM } from '@src/analytics/gtm';
import { gtmEvents } from '@src/analytics/gtmEventsConstant';
import React, { useContext } from 'react';

export default function SurveyForm({ programID, surveyButtonText }) {
  const state = useContext(GlobalStateContext);
  const onButtonClick = () => {
    GTM.track(gtmEvents.POST_COMPLETION_GET_MIN_SALARY_CLICKED, {
      type: programID || 'FULL_STACK',
      location: 'POST_APPLICATION_PAGE',
    });

    openInNewTab(
      'https://rebrand.ly/Crio-Survey' +
        (Boolean(state.utm) ? '?' + state.utm : ''),
    );
  };

  const openInNewTab = (url) => {
    if (window) {
      const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
      if (newWindow) newWindow.opener = null;
    }
  };

  return (
    <CrioButton
      variant="primary"
      className="whitespace-normal"
      onClick={onButtonClick}
    >
      {surveyButtonText ? surveyButtonText : 'Get your starting salary ranges!'}
    </CrioButton>
  );
}

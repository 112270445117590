import React from 'react';
import HallOfFameLogo from '../../../images/hall-of-fame/HallOfFameLogo.webp';
import { Link } from 'gatsby';

const NavbarPostApp = () => {
  return (
    <nav className=" relative z-[99] h-[100px]  bg-hall-of-fame-navbar">
      <div className="mx-auto flex h-full max-w-[1340px] items-center justify-around md:w-[90%]">
        <Link to="/hall-of-fame">
          <img
            src={HallOfFameLogo}
            alt="Hall Of Fame"
            height={60}
            width={160}
          />
        </Link>
      </div>
    </nav>
  );
};

export default NavbarPostApp;

import React, { useEffect, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

export default function FadeInWhenVisible(props) {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  const [hasComeInViewOnce, setHasComeInViewOnce] = useState(false);

  useEffect(() => {
    if (inView) {
      controls.start('visible');
      setHasComeInViewOnce(true);
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      transition={{ duration: props.duration ? props.duration : 0.8 }}
      {...props}
      className="w-full"
    >
      {hasComeInViewOnce ? props.children : ''}
    </motion.div>
  );
}
